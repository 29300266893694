import $ from 'jquery';

export class YouTubeApiModule {
	constructor(configuration) {
		this.configuration = configuration;
	}
	initialize(playerSelector = 'youtube-player') {
		// TODO: auto play o.b.v. in page url (hash nav)
		// 1. Hash waarde uit .location halen
		// 2. hash gebruiken als invoer voor het tonen van de video, mits deze in een van de video-anchors zit
		this.currentVideoId = null;
		this.playerSelector = playerSelector;
		this.player = null;

		if (this.configuration && this.configuration.cookiesAccepted && this.configuration.cookiesAccepted === true) {
			this.createScript();
			this.bindEventsAndCreatePlayer();
		} else {
			let videoElement = document.getElementById(playerSelector);
			if (videoElement) {
				videoElement.innerHTML = 'De door u gekozen cookie-voorkeur verhindert het afspelen van deze video.';
			}
		}
	}
	createScript() {
		let tag = document.createElement('script');
		tag.src = 'https://www.youtube.com/iframe_api';
		let firstScriptTag = document.getElementsByTagName('script')[0];
		firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
	}
	bindEventsAndCreatePlayer() {
		window['onYouTubeIframeAPIReady'] = (e) => {
			this.YT = window['YT'];
			this.configuration = Object.assign({
				events : {
					'onReady': this.onPlayerReady.bind(this),
					'onStateChange': this.onPlayerStateChange.bind(this)
				}
			}, this.configuration);
			this.createPlayer();
			this.onYouTubeIframeAPIReady();
		};
	}
	createPlayer() {
		this.player = new window['YT'].Player(this.playerSelector, this.configuration);
	}
	onYouTubeIframeAPIReady() {
		$('a.video-anchor .image-container').on('click', (event) => {
			// console.log('video anchor image container clicked', event.target);
			event.preventDefault();
			let anchor = event.target.parentElement.parentElement;
			let dataVideoId = anchor.getAttribute('data-video-id');
			let dataRevealId = anchor.getAttribute('data-reveal-id');
			// console.log('data-video-id', dataVideoId);
			// console.log('data-reveal-id', dataRevealId);
			let reveal = $('#' + dataRevealId);
			reveal.attr('data-video-id', dataVideoId);
			this.currentVideoId = dataVideoId;
		});

		// Foundation reveal open event handler, bound to this current module by fat arrow syntax
		$(document).on('opened.zf.reveal', '.video-modal', () => {
			this.shouldPlay = true;
			this.player.destroy(); // destroy, otherwise state changes are not handled
			this.createPlayer(); // recreate
		});

		// Foundation reveal closed event handler, bound to this current module by fat arrow syntax
		$(document).on('closed.zf.reveal', '.video-modal', () => {
			this.shouldPlay = false;
		});
		this.handleHashNavigation();
	}
	handleHashNavigation() {
		$(document).ready(() => {			
			if (window && window.location && window.location.hash.length && window.location.hash.length > 0) {
				Array.from(document.getElementsByClassName('video-anchor')).forEach((anchor) => {
					if (anchor.hasAttribute('data-video-id')) {
						let dataVideoId = anchor.getAttribute('data-video-id');
						let dataRevealId = anchor.getAttribute('data-reveal-id');
						if (`#${dataVideoId}` === window.location.hash) {
							this.shouldPlay = true;
							this.currentVideoId = dataVideoId;
							let reveal = $('#' + dataRevealId);
							// console.log('hash found in page');
							// console.log('data-video-id', dataVideoId);
							// console.log('data-reveal-id', dataRevealId);
							// console.log('shouldPlay is now', this.shouldPlay);
							reveal.foundation('reveal', 'open');
							setTimeout(() => {
								this.player.playVideo();
							}, 3000);
							// the player stays in the BUFFERING state
							// https://stackoverflow.com/questions/45364237/youtube-iframe-api-video-intermittently-playing-on-mobile-devices
						}
					}
				})
			}
		});
	}
	setConfiguration(configuration = {}) {
		this.configuration = configuration;
	}
	onPlayerReady(event) {
		this.player.cueVideoById(this.currentVideoId); // do load but do not yet play
		switch(this.shouldPlay) {
			case true:
			this.player.playVideo();
			break;
			case false:
			this.player.stopVideo();
			break;
		}
	}
	onPlayerStateChange(event) {
		// console.log('state change event happened, data is', event.data);
		let analyticsEventData = {
			'eventCategory':  'Auto play YouTube video',
			'eventAction':  'unknown',
			'eventLabel': this.currentVideoId
		}
		if (event.data > -2) {
			if (event.data == -1) {
			} else if (event.data === this.YT.PlayerState.ENDED) {
				if (ga && ga.getAll() && ga.getAll().length > 0) { // analytics
					analyticsEventData.eventAction = 'ended';
					ga.getAll()[0].send('event', analyticsEventData);
				}
				$('a.close-reveal-modal').trigger('click');
				// console.log('player state: ENDED');
			} else if (event.data == this.YT.PlayerState.PLAYING) {
				if (ga && ga.getAll() && ga.getAll().length > 0) { // analytics
					analyticsEventData.eventAction = 'playing';
					ga.getAll()[0].send('event', analyticsEventData);
				}
				// console.log('player state: PLAYING');
			} else if (event.data == this.YT.PlayerState.PAUSED) {
				// console.log('player state: PAUSED');
				if (ga && ga.getAll() && ga.getAll().length > 0) { // analytics
					analyticsEventData.eventAction = 'paused';
					ga.getAll()[0].send('event', analyticsEventData);
				}
			} else if (event.data == this.YT.PlayerState.BUFFERING) {
				// console.log('player state: BUFFERING');
				analyticsEventData.eventAction = 'buffering';
				ga.getAll()[0].send('event', analyticsEventData);
			} else if (event.data == this.YT.PlayerState.CUED) {
				// console.log('player state: CUED');
				analyticsEventData.eventAction = 'cued';
				ga.getAll()[0].send('event', analyticsEventData);
			}
		}
	}
}