import {DebounceModule} from './debounce.module';

export class VideoContainerFluidImageScalingModule {
	initialize() {
		this.initializeDebounceModule();
		
		this.handleResizeEvents();
		this.triggerResizeEvent();
	}
	initializeDebounceModule() {
		this.debounceModule = new DebounceModule();
	}

	handleResizeEvents() {
		$(window).resize(this.debounceModule.debounce(this.setToMainVideoContainerHeightToCenteredFluidImageNaturalHeight));
	}
	triggerResizeEvent() {
		$(window).trigger('resize'); // page load trigger resize
	}

	setToMainVideoContainerHeightToCenteredFluidImageNaturalHeight() {
		// console.log('sizeIt called!');
		let mainVideoContainer = $('.main-video-container .image-container');
		let mainVideoContainerFluidImage = mainVideoContainer.find('.centered-fluid-image');
		if (
			mainVideoContainer.length > 0
			&& mainVideoContainerFluidImage.length > 0
		) { 
			mainVideoContainer.css('height', mainVideoContainerFluidImage.height());
		}
	}
}