import { BaseModule } from "./base.module";
import { StorageModule } from "./storage.module";

export class FilterModule extends BaseModule {
	// https://www.w3schools.com/bootstrap4/bootstrap_filters.asp maybe use that example in the future for text search

	initialize() {
		this.hashNavEnabled = true;
		this.currentFilter = null;

		// define filter buttons and elements to be filtered
		this.filterButtons = document.querySelectorAll('[data-btn-filter]');
		this.filterElements = document.querySelectorAll('[data-element-filter]');

		// bind named click handlers to this object's context
		this.boundFilterButtonClickHandler = this.onFilterButtonClick.bind(this);
		this.boundFilterButtonHoverHandler = this.onFilterButtonHover.bind(this);
		this.boundFilterMouseLeaveHandler = this.onFilterButtonMouseLeave.bind(this);
		this.boundPreventDefaultClickEventHandler = this.preventDefaultClickEvent.bind(this);

		if (this.filterButtons) {
			this.filterButtons.forEach( (filterButton) => {
				// bind named event handlers
				filterButton.addEventListener('click', this.boundPreventDefaultClickEventHandler)
				filterButton.addEventListener('touchend', this.boundFilterButtonClickHandler);
				filterButton.addEventListener('mouseup', this.boundFilterButtonClickHandler);
				filterButton.addEventListener('mouseenter', this.boundFilterButtonHoverHandler);
				filterButton.addEventListener('mouseleave', this.boundFilterMouseLeaveHandler);
			});
		}
		if (this.filterButtons.length > 0 && this.filterElements.length > 0) {
			this.storageModule = new StorageModule();
			this.handleInPageAnchors();
		}
	}

	set filterIdentifier(filterIdentifier) {
		console.log('setting filter identifier to ', filterIdentifier);
		this._filterIdentifier = filterIdentifier;
	}

	get filterIdentifier() {
		return this._filterIdentifier;
	}

	handleInPageAnchors() {
		if (this.filterIdentifier && this.filterButtons.length > 0 && this.filterElements.length > 0) {
			let storedFilter = this.storageModule.getItem('sessionStorage', this.filterIdentifier);
			console.log('storedFilter', storedFilter);
			
			if (typeof storedFilter !== 'undefined' && storedFilter !== null) {
				window.location.hash = storedFilter;
			}
		}
		if (window && window.location && window.location.hash) {
			let hash = window.location.hash;
			this.filterButtons.forEach((filterButton) => {
				if (hash === `#${filterButton.dataset.btnFilter}` && this.hashNavEnabled === true)  {
					this.triggerEventOnElement('mouseup', filterButton);
				}
			});
		}
		this.hashNavEnabled = false;
	}

	handleActiveFilterDeselection() {
		this.filterElements.forEach((filterElement) => {
			filterElement.classList.remove('unmatched');
			filterElement.classList.add('matched');
		});
		if (this._filterIdentifier) {
			this.storageModule.removeItem('sessionStorage', this.filterIdentifier);
		}
		if ('replaceState' in window.history) {
			window.history.replaceState(null, '', '.');
		}
	}
	/**
	 * 
	 * @param {MouseEvent} event 
	 */
	onFilterButtonClick(event) {
		event.preventDefault(); // prevent normal anchor behavior if this is an anchor
		// active filter
		if (event.currentTarget.classList.contains('active')) {
			// active filter, disable
			event.currentTarget.classList.remove('active');
			this.handleActiveFilterDeselection(event);
			return;
		} else {
			//not active filter
			//deselect all active
			this.filterButtons.forEach((filterButton) => {
				filterButton.classList.remove('active');
			});
			//activate the single filter
			event.currentTarget.classList.add('active');
		}
		if (this.filterElements) {
			this.filterElements.forEach((filterElement) => {
				// unmatch all and match the elements that have the same btn/element filter attribute value
				filterElement.classList.add('unmatched');
				filterElement.classList.remove('matched');
				let elementFilter = filterElement.dataset.elementFilter;
				let btnFilter = event.currentTarget.dataset.btnFilter;
				window.location.hash = btnFilter;

				if (elementFilter == btnFilter) {
					filterElement.classList.add('matched');
					filterElement.classList.remove('unmatched');
					this.currentFilter = btnFilter;
					if (this._filterIdentifier) {
						this.storageModule.setItem('sessionStorage', this.filterIdentifier, this.currentFilter);
					}
				}
			});
		}
	}

	/**
	 * 
	 * @param {MouseEvent} event 
	 */
	onFilterButtonHover(event) {
		event.currentTarget.classList.add('hover');
	}

	/**
	 * 
	 * @param {MouseEvent} event 
	 */
	onFilterButtonMouseLeave(event) {
		event.currentTarget.classList.remove('hover');
	}

	/**
	 * 
	 * @param {MouseEvent} event 
	 */
	preventDefaultClickEvent(event) {
		event.preventDefault();
		
	}

}