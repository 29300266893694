import { loadCSS, grunticon, svgLoadedCallback } from './../utilities/grunticon-modern';
import scaleSVG from './../utilities/scale-inline-svg-polyfill';

export class ScalableVectorGraphicsModule {
	constructor(iconPath) {
		this.iconPath = iconPath;
	}

	initialize() {
		this.initializeScalableVectorGraphics();
	}

	// svgLoadedCallback( embedComplete ){		
	// 	// console.log('grunticon.embedSVG');
	// 	// grunticon.ready(() => {
	// 	// 	console.log('grunticon.ready');
	// 	// 	grunticon.embedIcons(grunticon.getIcons(grunticon.getCSS(grunticon.href)));
	// 	// 	if (typeof embedComplete !== 'undefined') {
	// 	// 		console.log('embedComplete defined, calling it');
	// 	// 		embedComplete();
	// 	// 	}
	// 	// 	// this.onEmbedComplete();
	// 	// });
	// }

	svgLoadedCallback(callbackOrElement, callback){
		if( grunticon.method !== "svg" ){
			return;
		}
		grunticon.ready(function(){
			var icons = grunticon.getIcons( grunticon.getCSS( grunticon.href ) );

			// if a callback was passed we assume the first param was
			// an element otherwise we just embed the icons
			if( typeof callback === "function" ){
				grunticon.embedIcons( callbackOrElement, icons );
				grunticon.callback();
			} else {
				grunticon.embedIcons( icons );
			}

			// if the first argument was a callback we call it after embedding the icons
			if ( typeof callbackOrElement === "function" ){
				callbackOrElement();
			}
			scaleSVG();
			document.documentElement.classList.add('svg-embedded');
		});
	};

	initializeScalableVectorGraphics() {
		let cacheBuster = '';
		let timeStamp = Date.now();
		// timeStamp comes from front-end project javascript.js
		if (typeof timeStamp !== 'undefined') {
			cacheBuster = '?cacheBuster=' + timeStamp;
		} else {
			timeStamp = Date.now();
			cacheBuster = '?cacheBuster=' + timeStamp;
		}
		if (this.iconPath) {
			grunticon.onSvgLoadedCallback = this.svgLoadedCallback;
			grunticon(
				[
				this.iconPath + "/icons.data.svg.css" + cacheBuster,
				this.iconPath + "/icons.data.png.css" + cacheBuster,
				this.iconPath + "/icons.fallback.css" + cacheBuster
			]
			,
			grunticon.onSvgLoadedCallback
			);
		}
	}

	onEmbedComplete() {
		console.log('embed complete handler in scalable vector graphics module');
		scaleSVG();
	}
	reload() {
		if (typeof grunticon !== 'undefined' && grunticon.embedSVG) {			
			grunticon.embedSVG();
			console.log('called grunticon embedSVG with no new arguments');
		}
	}

}