// import '@babel/polyfill';

//DOM collection polyfills from core-js
import 'core-js/modules/es.object.assign';
import 'core-js/modules/es.array.from';


// DOM polyfills mainly for IE11 (do export * from to make it go into the current lexical scope)
export * from './polyfills/Element.prototype.closest';
export * from './polyfills/NodeList.prototype.forEach';
export * from './polyfills/Element.prototype.matches';
import 'promise-polyfill/src/polyfill';
import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

//facultative stuff
import * as KUTE from 'kute.js';
import * as KUTESVG from 'kute.js/kute-svg';
import * as Cookies from 'js-cookie';

import { Application } from './application/application.computable';

let application = new Application();
application.initialize();

window.KUTE = KUTE;
window.KUTESVG = KUTESVG;
window.Application = Application;
window.Cookies = Cookies;

export {
	Application,
	KUTE,
	KUTESVG,
	Cookies
}
