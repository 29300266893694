export class BaseModule {
	/**
	 * 
	 * @param {string} eventName 
	 * @param {Element} element 
	 * @param {object} data
	 */
	triggerEventOnElement(eventName, element, data = { }) {
		// Create the event.
		var event = document.createEvent('Event');
		event.initEvent(eventName, true, true);
		event.data = data;
		element.dispatchEvent(event);
	}
	
	initialize() {
		
	}
}