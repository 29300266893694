import { SimpleBar } from 'simplebar';

export class CustomScrollBarModule {
	initialize() {
	}

	/**
	 * 
	 */
	getScrollBarUtility() {
		return this.scrollBarUtility;
	}
}