import {DebounceModule} from './debounce.module';
import {FilterModule} from './filter.module';
import { StorageModule } from './storage.module';
import '../polyfills/Element.prototype.closest';
import '../polyfills/Element.prototype.matches';

export class SearchModule {
	initialize() {
		this.initScroll = true;
		this.searchResultToNavigateTo = null;
		this.endPoint = 'http://acc1.search.jbitmedia.net/jbcontent/_search';
		this.storageModule = new StorageModule();
		this.mainSearchInputValue = this.storageModule.getItem('localStorage', 'currentSearchTerm');
		let currentSearchFrom = this.storageModule.getItem('localStorage', 'currentSearchFrom');
		let currentSearchSize = this.storageModule.getItem('localStorage', 'currentSearchSize');
		this.request = {
			"explain": true,
			"from": 0,
			"size": 10,
			"query": {
				"function_score" : {
					"query": {
						"bool" : {
						}
					},
					"linear": {
						"published": {
							"scale" : "9125d"
						}
					}
				}
			},
			"highlight": {
				"pre_tags" : ["<strong class=\"highlighted-text\">"],
				"post_tags" : ["</strong>"],
				"fragment_size": 225,
				"type": "plain",
				"number_of_fragments": 3, 
				"fragmenter": "simple",
				"fields": {
					"body": {
					},
					"title": {

					},
					"intro": {

					}
				}
			}
		};
		if (currentSearchFrom) {
			this.request.from = parseInt(currentSearchFrom, 10);
		} else {
			this.request.from = 0;
		}
		if (currentSearchSize) {
			this.request.size = parseInt(currentSearchSize, 10);
		} else {
			this.request.size = 10;
		}
		if (document.querySelector('.search-overview')) {
			this.response = {};
			this.activeFilters = new Map();
			this.filterEntries = [];
			this.activeSorting = null;
			this.debounceModule = new DebounceModule();
			// TODO: cache/optimize elements by combining
			this.mainSearchInput = document.querySelector('#main-search-field-container .search-input');
			this.mainSearchResultContent = document.querySelector('#main-search-result-content');
			this.mainSearchDropDownContent = document.querySelector('#main-search-dropdown-content');
			this.mainSearchField = document.querySelector("#main-search-field-container .search-field");
			this.filterButtons = document.querySelectorAll('#main-search-dropdown-content .filters-container .full-width.button');
			if (this.mainSearchField) {
				this.searchButton = this.mainSearchField.querySelector('.button-search');
				this.prefixedSearchIcon = this.mainSearchField.querySelector('.icon-prefix .prefixed-icon');
				this.affixedSearchIcon = this.mainSearchField.querySelector('.icon-affix .affixed-icon');
			}
			this.dropdownTrigger = document.querySelector('#main-search-dropdown-trigger');
			if (this.mainSearchInputValue) {
				this.mainSearchInput.value = this.mainSearchInputValue;

			}
			this.normalizeDomain();
			this.configureEndPointFallBack();
			this.configureDomainFallBack();
			// TODO: refactor all native events and their handlers into separate calls, combined in the addEventListeners function
			this.handleMainSearchInputEvents();
			this.handleMainSearchFocusEvent();
			this.handleMainSearchBlurEvent();
			this.initializeFilterModule();
			this.handleFilterActiveState();
			this.handleSortSwitches();
			this.triggerMainSearchInputEvent();
			this.addEventListeners();
			this.handleStoredFilters();
			this.handleStoredSorting();
			// this.scalableVectorGraphicsModule.reload();
		}
	}
	handleMainSearchFocusEvent() {
		if (this.mainSearchInput) {
			this.mainSearchInput.addEventListener('focus', (event) => {
				console.log('focus event', event);
				event.target.classList.add('focus');
				event.target.classList.remove('blur');
				
				// this.affixedSearchIcon.classList.add('hidden');
				this.prefixedSearchIcon.classList.remove('hidden');
				
				this.affixedSearchIcon.classList.remove('icon-magnify');
				this.affixedSearchIcon.classList.add('icon-close');
				this.affixedSearchIcon.dataset.grunticonEmbed = '';
				this.scalableVectorGraphicsModule.reload();
			});
		}
	}
	handleMainSearchBlurEvent() {
		if (this.mainSearchInput) {
			this.mainSearchInput.addEventListener('blur', (event) => {
				console.log('blur event', event);
				event.target.classList.add('blur');
				event.target.classList.remove('focus');
				this.prefixedSearchIcon.classList.add('hidden');
				
			});
		}
	}
	addEventListeners() {
		if (this.searchButton) {
			this.searchButton.addEventListener(
				'click',
				(event) => {
					event.preventDefault();
					this.respond();
				}
			);	
		}
		if (this.mainSearchResultContent) {
			this.mainSearchResultContent.addEventListener('click', (event) => {
				let closestSearchResultLink = event.target.closest('.search-result-link');
				if (closestSearchResultLink) {
					this.searchResultToNavigateTo = closestSearchResultLink.getAttribute('href');
				}
				let closestReadMoreButton = event.target.closest('[data-load-more-results]');
				if (closestReadMoreButton) {
					this.request.size += 10;
					this.handleRequest( () => {
						this.clearTemplate();
						this.handleEmptyResult();
						this.renderSuggestions();
						this.renderSearchResults();
						// TODO: only show 'load more' button if there are more results to load (this information should be high in the hierarchy of the response object)
						this.renderLoadMoreButton();
						this.suggestSearchTerm();
						this.storageModule.setItem('localStorage', 'currentSearchFrom', this.request.from);
						this.storageModule.setItem('localStorage', 'currentSearchSize', this.request.size); 
					} );
				}
			});
		}
		window.addEventListener('beforeunload', (event) => {
			this.storageModule.setItem('localStorage', 'currentSearchResultElement', this.searchResultToNavigateTo);
			this.storageModule.setItem('localStorage', 'currentSearchFrom', this.request.from);
			this.storageModule.setItem('localStorage', 'currentSearchSize', this.request.size);
		});
		this.affixedSearchIcon.addEventListener('click', (event) => {
			console.log('affixedSearchIcon click', event, event.target);
			let closestAffixedIcon = event.target.closest('.affixed-icon');
			if (closestAffixedIcon) {
				if (closestAffixedIcon.classList.contains('icon-close')) {
					console.log('contains closed icon');
					let icon = event.target.closest('.affixed-icon');
					icon.classList.remove('icon-close');
					icon.classList.add('icon-magnify');
					// icon.innerHTML = '';
					this.scalableVectorGraphicsModule.reload();
					// TODO: DRYness of svg reloading given changeroo
					this.mainSearchInput.value = '';
					this.triggerMainSearchInputEvent();
	
				}else {
					if (closestAffixedIcon.classList.contains('icon-magnify')) {
						console.log('contains magnify icon');
						let icon = event.target.closest('.affixed-icon');
						icon.classList.remove('icon-magnify');
						icon.classList.add('icon-close');
						// icon.innerHTML = '';
						this.scalableVectorGraphicsModule.reload();
						this.focusOnMainSearchInput();
					}
				}
			}
		});
		this.prefixedSearchIcon.addEventListener('click', (event) => {
			console.log('prefixedSearchIcon click');
		});
	}
	renderMainSearchResultContent(templatePart) {
		this.mainSearchResultContent.innerHTML = templatePart;
	}

	appendToMainSearchResultContent(templatePart) {
		this.mainSearchResultContent.innerHTML += templatePart;
	}

	clearTemplate() {
		this.concatenated = '';
		this.mainSearchResultContent.innerHTML = '';
	}
	triggerMainSearchInputEvent() {
		let inputEvent = document.createEvent('HTMLEvents');
		inputEvent.initEvent('input', false, true);
		if (this.mainSearchInput) {
			this.mainSearchInput.dispatchEvent(inputEvent);
		}
	}
	normalizeDomain() {
		let hostNameProxy = window.location.hostname;
		this.domain = hostNameProxy;
		let splitHostName = hostNameProxy.split('.');
		if (splitHostName.length > 2) {
			let lastTwoParts = splitHostName.slice(-2);
			this.domain = lastTwoParts[0] + '.' + lastTwoParts[1];
		}
	}

	configureDomainFallBack() {
		switch(this.domain) {
			case 'localhost':
			case 'jbitmedia.net':
				this.domain = 'computable.nl';
		}
	}

	configureEndPointFallBack() {
		console.log('configureEndPointFallback', this.domain);
		
		switch(this.domain) {
			case 'localhost':
				this.endPoint = 'http://localhost';
			break;
		}
	}

	initializeFilterModule() {
		let filterModule = new FilterModule();
		filterModule.initialize(
			document.getElementById('main-search-dropdown-trigger'),
			document.getElementById('main-search-dropdown-content')
		);
	}
	focusOnMainSearchInput() {
		if (this.mainSearchInput) {
			this.mainSearchInput.focus();
			this.buildQuery();
		}
	}
	resetPaging() {
		this.request.from = 0;
		this.request.size = 10;
		this.storageModule.setItem('localStorage', 'currentSearchFrom', this.request.from);
		this.storageModule.setItem('localStorage', 'currentSearchSize', this.request.size);
	}
	handleMainSearchInputEvents() {
		if (this.mainSearchInput) {
			this.mainSearchInput.addEventListener('input', (event) => {
				if (event.inputType) {
					console.log('event.inputType', event.inputType);
					
					switch(event.inputType) {
						case 'insertText':
						case 'deleteContentBackwards':
							let searchOverview = document.querySelector('.search-overview');
							if (searchOverview) {
								console.log('searchOverview', searchOverview);
								searchOverview.scrollTop = 0;
							}
						break;
					}
				}
				console.log('main search input event and target in it', event, event.target);
				this.mainSearchInput = event.target;
				this.mainSearchInputValue = this.mainSearchInput.value.toString();
				if (this.mainSearchInputValue.length > 0) {
					console.log('main search input event: it has some text');
					// this.affixedSearchIcon.classList.remove('icon-magnify');
					// this.affixedSearchIcon.classList.add('icon-close');
					// this.affixedSearchIcon.dataset.grunticonEmbed = '';
					// this.scalableVectorGraphicsModule.reload();
					// this.resetPaging();
					if (this.mainSearchDropDownContent) {
						this.mainSearchDropDownContent.classList.remove('dropdown-collapsed');
						this.mainSearchDropDownContent.classList.add('dropdown-open');
					}					
				}
				if (this.mainSearchInputValue.length === 0) {
					console.log('main search input event: it has no text');
					this.affixedSearchIcon.classList.remove('icon-close');
					this.affixedSearchIcon.classList.add('icon-magnify');
					this.affixedSearchIcon.dataset.grunticonEmbed = '';
					this.scalableVectorGraphicsModule.reload();
					if (this.mainSearchDropDownContent) {
						this.mainSearchDropDownContent.classList.remove('dropdown-open');
						this.mainSearchDropDownContent.classList.add('dropdown-collapsed');
					}
				}
				this.storageModule.setItem('localStorage', 'currentSearchTerm', this.mainSearchInputValue);
			});
			this.mainSearchInput.addEventListener('input',
				this.debounceModule.debounce((event) => {
					this.buildQuery();
					this.buildSuggestions();
					this.response = this.respond();
					// this.scalableVectorGraphicsModule.reload();
				}, 200)
			);
		}
	}
	buildSuggestions() {
		this.request.suggest = {
			"text" : this.mainSearchInputValue
		};
		// this.request.suggest.suggestion_1 = {
		// 	"term" : {
		// 		"field": "title"
		// 	}
		// }
		// this.request.suggest.suggestion_2 = {
		// 	"term" : {
		// 		"field": "intro"
		// 	}
		// }
		this.request.suggest.suggestion = {
			"term" : {
				"field": "body"
			}
		}
	}
	buildQuery() {

		this.request.query.function_score.query.bool.must = [];
		this.request.query.function_score.query.bool.should = [];


		// nested must/should boolean queries. See https://stackoverflow.com/questions/28538760/elasticsearch-bool-query-combine-must-with-or
		// Goal: only return results that have the searched text (and/or parts of it) in it.
		// What it should return roughly in a logical AND fashion:
		// - content type MUST be one of the filter entries (term)
		// AND
		// - title, intro or body MUST match main search input value [individual words in the main search input value] (match)
		// AND
		// - title, intro, or body SHOULD match a phrase in the main search input value (match_phrase) [full phrase]

		// ... so: the match and match_phrase queries should be nested into the content type .must array. These should be built first and then put into the ctype object

		let must = [];
		let combinedShould = [];

		if (this.filterEntries.length > 0) {
			must.push(
				{
					"terms" : { 
						"ctype" : this.filterEntries
					}
				}
			);
		}

		if (this.domain) {
			must.push(
				{
					"term" : {
						"domain" : this.domain
					}
				}
			);
		}

		if (this.mainSearchInputValue) {
			 let matchLikeConfigurations = [
				 {
					"title" : {
						"query": this.mainSearchInputValue,
					}
				 },
				 {
					"intro" : {
						"query": this.mainSearchInputValue,
					},
				 },
				 {
					"body" : {
						"query": this.mainSearchInputValue,
					}
				 }
			 ];

			 let termLikeConfigurations = [
				{
					"title" : {
						"value": this.mainSearchInputValue,
					}
				},
				{
					"intro" : {
						"value": this.mainSearchInputValue,
					},
				},
				{
					"body" : {
						"value": this.mainSearchInputValue,
					}
				}
			];

			matchLikeConfigurations.forEach( (matchLikeConfiguration) => {
				combinedShould.push(
					{
						"match" : matchLikeConfiguration
					}
				);
				combinedShould.push(
					{
						"match_phrase" : matchLikeConfiguration
					}
				);
			} );

			termLikeConfigurations.forEach( (termLikeConfiguration) => {
				combinedShould.push(
					{
						"term" : termLikeConfiguration
					}
				);
			} );
		}
		must.push(
			{
				bool: {
					should: combinedShould
				}
			}
		);
		
		this.request.query.function_score.query.bool.must = must;
	}

	respond() {
		console.log('request data is', this.request);
		this.mainSearchInputValue = this.mainSearchInput.value.toString();
		console.log('respond, main search input value is now', this.mainSearchInputValue);
		
		if (this.mainSearchInputValue) {
			if (this.mainSearchInputValue.length > 0) {
				this.handleRequest(
					() => {
						this.disableBodyScroll();
						this.clearTemplate();
						this.handleEmptyResult();
						this.renderSuggestions();
						this.renderSearchResults();
						this.renderLoadMoreButton();
						this.suggestSearchTerm();
					}
				);
			}
		}else {
			this.enableBodyScroll();
			this.mainSearchResultContent.innerHTML = '';
		}
		return this.response;
	}

	disableBodyScroll() {
		document.documentElement.style.overflow = 'hidden';
	}
	enableBodyScroll() {
		document.documentElement.style.overflow = 'auto';
	}

	handleSortSwitches() {
		let sortingButtons = document.querySelectorAll('.sort-by-container .full-width.button');
		// TODO: delegated, instead of iterated
		Array.from(sortingButtons).forEach((sortingButton) => {
			sortingButton.addEventListener('click', (event) => {
				event.target.classList.toggle('active');
				if (event.target.getAttribute('data-sort-by') != null) {
					var sortingData = {};
					sortingData.sortByIdentifier = event.target.getAttribute('data-sort-by');
					sortingData.sortByLabel = event.target.getAttribute('data-sort-by-label');
				}
				if (sortingData) {
					if (event.target.classList.contains('active') === true) {
						this.activeSorting = sortingData;
						console.log('sorting data:', this.activeSorting);
						this.storageModule.setItem('localStorage', 'active-main-search-sorting', JSON.stringify(this.activeSorting.sortByIdentifier));
					}
					if (event.target.classList.contains('active') === false) {
						this.activeSorting = null;
						this.storageModule.setItem('localStorage', 'active-main-search-sorting', null);
					}
				}
				this.request.sort = undefined;
				if (this.activeSorting && this.activeSorting.sortByIdentifier) {
					this.request.sort = [];
					let sort = {};
					sort[this.activeSorting.sortByIdentifier] = {
						"order": "desc"
					}
					this.request.sort.push(sort);
				}
				// activate/deactivate button active class
				Array.from(sortingButtons).forEach( (button) => {
					if (this.activeSorting && button.getAttribute('data-sort-by') !== this.activeSorting.sortByIdentifier ) {
						button.classList.remove('active');
					}
				} );
				this.respond();
			});
		});
	}

	handleFilterActiveState() {
		// Filter 'none' and the others. Handle button states and behavior
		// TODO: delegated, instead of iterated
		Array.from(this.filterButtons).forEach( (filterButton) => {
			filterButton.addEventListener('click', (event) => {
				event.target.classList.toggle('active'); // ('active');

				console.log('data-filter', event.target.getAttribute('data-filter'));
				
				if (event.target.getAttribute('data-filter') != null) {
					var filterData = event.target.getAttribute('data-filter');
					var filterData = {
						filterIdentifier : event.target.getAttribute('data-filter'),
						filterLabel : event.target.getAttribute('data-filter-label'),
					}
					// handle no filter
					if (event.target.getAttribute('data-filter') === '' && event.target.classList.contains('active') === true) {
						this.activeFilters.clear(); // clear active filters because we chose all filters
						filterData = null;
						this.filterEntries = [];


						// deactivate other buttons, that have a filter data-attribute that has something filled in
						this.filterButtons.forEach(filterButton => {
							if (filterButton.getAttribute('data-filter').length > 0 ) {
								filterButton.classList.remove('active');
							}
						});
					}
					if (event.target.getAttribute('data-filter').length > 0) {
						let allButton = document.querySelector('[data-all-content-types]');
						if (allButton) {
							allButton.classList.remove('active');
						}
					}
				}


				if (filterData) {
					if (event.target.classList.contains('active') === true) {
						console.log('filter data:', filterData);
						this.activeFilters.set(filterData.filterIdentifier, filterData);
					
					}
					if (event.target.classList.contains('active') === false) {
						this.activeFilters.delete(filterData.filterIdentifier, filterData);
					}
					let filterEntries = [];
					let filterLabels = [];
					for(let entry of this.activeFilters.values()) {
						filterEntries.push(entry.filterIdentifier);
						filterLabels.push(entry.filterLabel);
					}
					console.log('current active filters:', this.activeFilters);
					this.filterEntries = filterEntries;
				}
				this.storageModule.setItem('localStorage', 'active-main-search-filters', JSON.stringify([...this.activeFilters]));
				this.buildQuery();
				this.respond();
			});
		} );
	}

	handleRequest(responseCallBack) {
		let xhr = new XMLHttpRequest();
		xhr.open('POST', this.endPoint);
		xhr.timeout = 8000;
		xhr.setRequestHeader('Content-Type', 'application/json');
		xhr.onload = () => {
			if (xhr.status === 200) {
				//console.log('Response OK');
				this.response = JSON.parse(xhr.responseText);
				responseCallBack();
			} else {
				this.renderMainSearchResultContent(`Er ging iets fout tijdens het laden van de resultaten (HTTP code ${xhr.status}).`);
			}
		}
		xhr.ontimeout = () => {
			this.renderMainSearchResultContent(`Het duurde te lang voordat er een zoekresultaat terug zou komen.`);
		}
		xhr.onerror = () => {
			this.renderMainSearchResultContent(`De zoekresultaten kunnen momenteel niet geladen worden. Neem contact op met technology@computable.nl.`);
		}
		xhr.send(JSON.stringify(this.request));
	}

	handleEmptyResult() {
		let concatenated = '';
		if (this.response.hits.hits.length === 0) {
			concatenated += '<div>Niets gevonden...</div>';
		}
		this.appendToMainSearchResultContent(concatenated);
	}

	renderSuggestions() {
		if (this.response && this.response.suggest && this.response.suggest.suggestion ) {
			if (this.response.suggest.suggestion.length > 0) {
				let simpleSuggestionArray = [];
				let concatenated;
				this.response.suggest.suggestion.forEach(s => {
					console.log('suggestion', s);
					if (s.options.length > 0) {
						if (s.options[0].text) {
							simpleSuggestionArray.push(s.options[0].text); // the most likely, top suggestion option is used.
						}
					}
				});
				if (simpleSuggestionArray.length > 0) {
					concatenated = `
					<div class="search-suggestion-container">
						Bedoelde u <span class="search-suggestion">${ simpleSuggestionArray.join(' ') }</span>?
					</div>
				`
				this.appendToMainSearchResultContent(concatenated);
				}
			}
		}
	}

	renderSearchResults(t) {
		
		let concatenated = '';

		if (this.response && this.response.hits && this.response.hits.hits) {

			for (let item of this.response.hits.hits) {
				// TODO: check for presence of all properties!
				// console.log('item found:', item._source.snapshot);
				let created = '';
				let body = '';
				let title = item._source.title;
				if (item._source.published) {
					let createdDate = new Date(
						Date.parse(
							item._source.published
						)
					);
					let createdAtDate = createdDate.toLocaleDateString();
					let createdAtTime = createdDate.toLocaleTimeString();
					created = `<span data-grunticon-embed class="search-result-small-icon icon-clock-outline"></span> ${createdAtDate} om ${createdAtTime}`;
				}
				let url = `${item._source.url}`;
				let ctype = item._source.ctype;
				let icon = 'file-document-outline';
				//console.log('ctype', ctype);
				let contentType = '';
				let highlightFieldKeys = ['title', 'intro', 'body'];
				switch (ctype) {
					case 'article':
						icon = 'file-document-outline-primary';
						contentType = 'Artikel';
						break;
					case 'company':
						icon = 'domain-primary';
						contentType = 'Bedrijf';
						break;
					case 'whitepaper':
						icon = 'file-pdf';
						contentType = 'White&#8203;paper';
						break;
					case 'person':
						icon = 'account-search';
						contentType = 'Lid';
						break;
					default:
						break;
				}
				if (item.highlight) {
					highlightFieldKeys.forEach( (highlightFieldKey) => {
						if (item.highlight[highlightFieldKey]) {
							if (item.highlight[highlightFieldKey].length > 0) {
								let highlight = item.highlight[highlightFieldKey][0];
								switch(highlightFieldKey) {
									case 'title':
										title = ` ${highlight}`; // only the title with highlights
									break;
									case 'body':
									body = ` ${highlight} ...`; //
									break;
									case 'intro':
										body = ` ${highlight}...`; // this is added to the body
									break;
								}
							}
						}
					} );
				}

				concatenated += `
					<div class="search-result-container">

					<a class="search-result-link" href="${url}">
						<div class="search-result-item margin-bottom-10">
							<div class="row">
								<div class="content-type-column column">
									<div data-grunticon-embed class="search-result-large-icon icon-${icon}"></div>
									<div class="content-type text-center full-width">
										${contentType}
									</div>
								</div>
								<div class="highlight-column column">
									<h3>${title}</h3>
									<div class="search-result-relevant-text-container">
										${body}
									</div>
									<div class="search-result-item-info">
										<span class="date">
											${created}
										</span>
									</div>
								</div>
							</div>
							<div class="row">
					
							</div>
						</div>
					</a>
				</div>

				`;
			}
			//console.log('concatenated:', concatenated);
		}
		this.appendToMainSearchResultContent(concatenated);
		this.scalableVectorGraphicsModule.reload();
		this.mainSearchResultContent.querySelectorAll('.search-result-link').forEach((link)=> {
		link.classList.remove('search-result-link-found');
		if (link.getAttribute('href') === 
				this.storageModule.getItem('localStorage', 'currentSearchResultElement')
			) {
				console.log(link.getAttribute('href'), this.storageModule.getItem('localStorage', 'currentSearchResultElement'));
				console.log('matched!');
				// important: here the scroll position is set to the local storage saved element's top scroll position
				//			  and afterwards the from, size (pagination variables) and current search result element (url) are removed because the item was found
				let searchOverview = document.querySelector('.search-overview');
				if (searchOverview) {
					console.log('search overview detected');
					
					searchOverview.scrollTop = link.offsetTop;
					link.classList.add('current-search-result-link');
					link.classList.remove('current-search-result-link');
				}
				this.storageModule.removeItem('localStorage', 'currentSearchResultElement'); // after matching, remove the current search result element
				var e = document.createEvent('HTMLEvents');
				e.initEvent('mouseover', false, true);
				link.dispatchEvent(e);
			}
		});
	}

	renderLoadMoreButton() {
		console.log('renderLoadMoreButton called'); 
		this.appendToMainSearchResultContent(`
		<button data-load-more-results class="full-width load-more-button button">
			<div class="button-icon icon-caret-primary no-pointer-events"></div>
			Meer resultaten laden
		</button>
		`);
	}

	suggestSearchTerm() {
		let suggestionElement = this.mainSearchResultContent.querySelector('.search-suggestion');
		console.log('suggestionElement', suggestionElement);
		if (suggestionElement) {
			let suggestion = suggestionElement.innerHTML;
			console.log('suggestion text', suggestion);
			
			suggestionElement.addEventListener('click', (event) => {
				console.log('suggestion is', event.target.innerHTML);
				this.mainSearchInput.value = event.target.innerHTML;
				this.clearTemplate();
				this.triggerMainSearchInputEvent();
				this.scalableVectorGraphicsModule.reload();
			});
		}

	}

	setScalableVectorGraphicsModule(scalableVectorGraphicsModule) {
		this.scalableVectorGraphicsModule = scalableVectorGraphicsModule;
	}

	handleStoredFilters() {
		this.storedFilters = this.storageModule.getItem('localStorage', 'active-main-search-filters');
		if (this.storedFilters && this.storedFilters.length > 0) {
			console.log('active main search filters, from storage', this.storedFilters);
			this.activeFilters = new Map(JSON.parse(this.storedFilters));
			this.filterButtons.forEach((filterElement) => {
				console.log('filterElement', filterElement);
				this.activeFilters.forEach( (activeFilter) => {
					console.log('filter element active filter', activeFilter, filterElement.dataset.filter);
					if (filterElement.dataset.filter === activeFilter.filterIdentifier) {
						console.log('same:', filterElement.dataset.filter, activeFilter.filterIdentifier);
						filterElement.click();
					} 
				} );
			});
		}
	}
	handleStoredSorting() {
		this.storedSorting = JSON.parse(this.storageModule.getItem('localStorage', 'active-main-search-sorting'));
		if (this.storedSorting) {
			console.log('stored sorting present', this.storedSorting);
			
			document.querySelectorAll('.sort-by-container [data-sort-by]').forEach((sortElement) => {
				console.log('iterating sort element' , sortElement, sortElement.dataset);
				
				if (sortElement.dataset.sortBy === this.storedSorting) {

					sortElement.click();
				}
			});
		}
	}
}
