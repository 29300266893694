export class StorageModule {

	/**
	 * 
	 * @param {string} type  - 'sessionStorage' or 'localStorage' 
	 * @param {*} key 
	 * @param {*} value 
	 */
	setItem(type, key, value) {
		// console.log('will set item', type, key, value);
		
		if (this.storageAvailable(type) === true) {
			// console.log('storage available for type', type);
			
			if (type === 'sessionStorage') {
				// console.log('session storage set', key, value);
				return sessionStorage.setItem(key, value);
			}
			if (type === 'localStorage') {
				// console.log('local storage set', key, value);
				return localStorage.setItem(key, value);
			}
		}
	}
	/**
	 * 
	 * @param {string} type  - 'sessionStorage' or 'localStorage'
	 * @param {*} key 
	 */
	getItem(type, key) {
		//console.log('will get item', type, key);
		if (this.storageAvailable(type) === true) {
			// console.log('storage available for type', type);
			if (type === 'sessionStorage') {
				// console.log('session storage get', key);
				return sessionStorage.getItem(key);
			}
			if (type === 'localStorage') {
				// console.log('local storage get', key);
				
				return localStorage.getItem(key);
			}
		}
	}

	removeItem(type, key) {
		//console.log('will get item', type, key);
		if (this.storageAvailable(type) === true) {
			// console.log('storage available for type', type);
			if (type === 'sessionStorage') {
				// console.log('session storage get', key);
				return sessionStorage.removeItem(key);
			}
			if (type === 'localStorage') {
				// console.log('local storage get', key);

				return localStorage.removeItem(key);
			}
		}
	}

	/**
	 * 
	 * @param {string} type  - 'sessionStorage' or 'localStorage'
	 */
	storageAvailable(type) {
		try {
			var storage = window[type],
			x = '__storage_test__';
			storage.setItem(x, x);
			storage.removeItem(x);
			return true;
		}
		catch(e) {
			return e instanceof DOMException && (
				// everything except Firefox
				e.code === 22 ||
				// Firefox
				e.code === 1014 ||
				// test name field too, because code might not be present
				// everything except Firefox
				e.name === 'QuotaExceededError' ||
				// Firefox
				e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
				// acknowledge QuotaExceededError only if there's something already stored
				storage.length !== 0;
		}
	}
}