import {AnimationModule} from '../modules/animation.module';

// this code is used in the topic-switch-dropdown
// TODO: reintroduce initial height: allow animation from non-zero height. See leveranciersprofielen.
export class DropDownModule {
	initialize() {
		this.animationModule = new AnimationModule();
		this.setCollapsedDropDownHeights();
		this.setEventListeners();
	}
	
	setCollapsedDropDownHeights() { 
		let collapsedDropDowns = document.querySelectorAll('.dropdown-content.dropdown-collapsed');
		if (collapsedDropDowns) {
			collapsedDropDowns.forEach((collapsedDropDown) => {				
				collapsedDropDown.dataset.initialHeight = collapsedDropDown.offsetHeight;
				console.log('collapseddropdown set height', collapsedDropDown, collapsedDropDown.dataset.initialHeight);
			});
		}
	}
	
	setEventListeners() {
		if (window) {
			window.addEventListener('load', (event) => {
				this.onWindowLoaded(event);
			});
		}
	}
	
	onWindowLoaded(event) {		
		let dropDownContainers = document.querySelectorAll('.dropdown-container');
		if (dropDownContainers) {
			dropDownContainers.forEach((dropDownContainer) => {
				// console.log('dropdown container', dropDownContainer);
				dropDownContainer.addEventListener('click', (event) => {
					// console.log('dropdown container click', event, event.target, dropDownContainer.querySelector('.dropdown-trigger.dropdown-collapsed'));
					let collapsedDropDownTrigger = dropDownContainer.querySelector('.dropdown-trigger.dropdown-collapsed'); // .dropdown-trigger
					let openDropDownTrigger = dropDownContainer.querySelector('.dropdown-trigger.dropdown-open'); // .dropdown-trigger
					if (collapsedDropDownTrigger) {
						// console.log('collapseddropdowntrigger', collapsedDropDownTrigger);
						collapsedDropDownTrigger.classList.remove('dropdown-collapsed');
						collapsedDropDownTrigger.classList.add('dropdown-open');
						let currentTarget = event.target;
						let data = {};
						data.content = collapsedDropDownTrigger.dataset.dropdownContent;
						data.openText = collapsedDropDownTrigger.dataset.dropdownOpenText;
						// console.log('data',data);
						if (data.openText) {
							// console.log('open text present');
							let dropDownText = collapsedDropDownTrigger.querySelector('.dropdown-text');
							if (dropDownText) {
								// console.log('dropdown text present');
								dropDownText.innerHTML = data.openText;
							}
						}
						// alert('test');
						let dropDownContent = document.getElementById(`${data.content}`);
						if (dropDownContent) {
							if (dropDownContent.dataset.dropdownAnimate) {
								// console.log('would animate now');
								this.animationModule.autoHeightAnimate(dropDownContent, 400, () => {
									dropDownContent.style.height = 'auto';
								});
							} else {
								// immediate, fallback
								dropDownContent.style.height = 'auto';
							}
							dropDownContent.classList.add('dropdown-open');
							dropDownContent.classList.remove('dropdown-collapsed');
						}
						return;
					}
					else {
						if (openDropDownTrigger) {
							// console.log('opendropdowntrigger', openDropDownTrigger);
							openDropDownTrigger.classList.remove('dropdown-open');
							openDropDownTrigger.classList.add('dropdown-collapsed');
							// console.log('currenttarget', currentTarget);
							let data = {};
							data.content = openDropDownTrigger.dataset.dropdownContent;
							data.closedText = openDropDownTrigger.dataset.dropdownClosedText;
							// console.log('data', data);
							if (data.closedText) {
								// console.log('open text present');
								let dropDownText = openDropDownTrigger.querySelector('.dropdown-text');
								if (dropDownText) {
									// console.log('dropdowntext present');
									dropDownText.innerHTML = data.closedText;
								}
							}
							let dropDownContent = document.getElementById(`${data.content}`);
							if (dropDownContent) {
								// console.log('dropdowncontent present', dropDownContent);
								
								if (dropDownContent.dataset.initialHeight) {
									// console.log('would animate now, closing');
									let cachedCurrentHeight = parseFloat(dropDownContent.offsetHeight);
									// console.log('cached current height', cachedCurrentHeight);
									
									if ( dropDownContent.dataset.dropdownAnimate === 'true') {
										this.animationModule.animate(
										{
											element: dropDownContent,
											timing(t) {
												return t;
												// return Math.sqrt(t);
												// return t<.5 ? 2*t*t : -1+(4-2*t)*t;
												// ease-in-out-cubic
												// https://gist.github.com/gre/1650294
											},
											draw(progress) {
												// content.scrollTop -= change;
												let diff = progress * ( cachedCurrentHeight - dropDownContent.dataset.initialHeight )
												dropDownContent.dataset.initialHeight;
												cachedCurrentHeight;
												progress;
												let height = cachedCurrentHeight - diff;
												let heightString = `${height}px`;
												console.log('progress at', progress, 'height at', heightString);

												dropDownContent.style.height = heightString;
											},
											duration: 400,
											callback: (element) => {
												dropDownContent.style.height = dropDownContent.dataset.initialHeight;
											}
										});
									} else {
										//immediate, fallback
										dropDownContent.style.height = dropDownContent.dataset.initialHeight;
									}
								}
								dropDownContent.classList.add('dropdown-collapsed');
								dropDownContent.classList.remove('dropdown-open');
							}
						}
					}
					
				});
			});
		}
		let dropDownCloseTriggers = document.querySelectorAll('.dropdown-close-trigger');
		if (dropDownCloseTriggers) {			
			dropDownCloseTriggers.forEach((dropDownCloseTrigger) => {
				// console.log('dropdownclosetrigger', dropDownCloseTrigger);
				dropDownCloseTrigger.addEventListener('click', (event) => {
					event.preventDefault();
					// console.log('running click event listener to dropdown close trigger');
					// console.log('event and target are', event, event.target);
					let closestDropDownTrigger = event.target.closest('.dropdown-close-trigger'); 
					if (closestDropDownTrigger) {
						// console.log('event target matches .dropdown-close-trigger');
						let data = {};
						data.trigger = closestDropDownTrigger.dataset.dropdownTrigger;
						// console.log('data for dropdownclosetrigger is', data);
						if (data.trigger) {
							// console.log('trigger data found in data');
							let trigger = document.getElementById(data.trigger);
							if (trigger) {
								// console.log('trigger element found by id, will click it now');
								trigger.click();
							}
						}
					}
				});
			});
		}	
	}
}