import {AnimationModule} from '../modules/animation.module';
import SimpleBar from 'simplebar';
import '../polyfills/Element.prototype.closest';

if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
}

export class MainNavigationModule {
	initialize() {
		this.navigationContainerCache = [];
		this.animationModule = new AnimationModule();
		this.previousSelection = null;
		this.setupMainNavigationContainers();
		this.setupPullTabs();
		//console.log('initing main nav module');
		// TODO: refactor
		// TODO: pull-tab functionality. Probably no animation module needed...
	}
	setupMainNavigationContainers() {
		let mainNavigationContainers = document.querySelectorAll('.main-navigation-container');
		mainNavigationContainers.forEach((mainNavigationContainer) => {
			if (mainNavigationContainer) {
				let mode = this.detectMode(mainNavigationContainer);
				let mainNavigation = mainNavigationContainer.querySelector('.main-navigation');
				if (mainNavigation) {
					let simpleBarElement = this.initializeSimpleBarOnElement(mainNavigationContainer);
					let mainLevelListItems = mainNavigation.querySelectorAll('.main-level-list-item');
					this.setSimpleBarScrollElementScrollTopPositionToFirstMainNavigationPosition(mainLevelListItems, simpleBarElement);
					this.deactiveActiveMainLevelListItems(mainLevelListItems);
					mainNavigation.addEventListener('click', (event) => {
						// console.log(event.target, event);
						if (event.target.classList.contains('main-level-list-item')) {
							this.handleMainLevelListItemClick(event, mainNavigationContainer, mode);
						}
					})
				}
			}
		});
	}
	setupPullTabs() {
		let pullTabs = document.querySelectorAll('.pull-tab');
		if (pullTabs) {
			pullTabs.forEach( (pullTab) => {
				pullTab.addEventListener('click', (event) => {
					console.log('pull tab click event', event);
					let closestMainNavigationPositioningContainer =  event.target.closest('.main-navigation-positioning-container');
					let mainNavigationContainer = closestMainNavigationPositioningContainer.querySelector('.main-navigation-container');

					console.log('closestMainNavigationPositioningContainer', closestMainNavigationPositioningContainer);
					console.log('mainNavigationContainer', mainNavigationContainer);
					
					if (event.target.classList.contains('active') === true) {
						event.target.classList.remove('active');
							mainNavigationContainer.style.marginLeft = `${-mainNavigationContainer.offsetWidth}px`;
					} else {
						if (event.target.classList.contains('active') === false) {
							event.target.classList.add('active');
							mainNavigationContainer.style.marginLeft = 0;
						}
					}
				});
			} );
		}
	}
	detectMode(mainNavigationContainer) {
		let mode;
		if (mainNavigationContainer.classList.contains('top-side-navigation-container')) {
			mode = 'top';
		} else {
			if (mainNavigationContainer.classList.contains('left-side-navigation-container')) {
				mode = 'left';
			}
		}
		return mode;
	}
	initializeSimpleBarOnElement(element) {
		element = new SimpleBar(element);
		return element;
	}
	setSimpleBarScrollElementScrollTopPositionToFirstMainNavigationPosition(mainLevelListItems, simpleBarElement) {
		if (mainLevelListItems.length > 0) {
			let scrollTop = mainLevelListItems[0].offsetTop;
			simpleBarElement.getScrollElement().scrollTop = scrollTop;
		}
	}
	deactiveActiveMainLevelListItems(mainLevelListItems) {
		mainLevelListItems.forEach((mainLevelListItem) => {
			// console.log('main level list item', mainLevelListItem);
			if (mainLevelListItem.classList.contains('active')) {
				mainLevelListItem.classList.remove('active');
			}

		});
	}
	handleMainLevelListItemClick(event, mainNavigationContainer, mode) {
		let active = !event.target.classList.contains('active');
		let mainNavigation = mainNavigationContainer.querySelector('.main-navigation');
		if (mainNavigation) {
			mainNavigation.querySelectorAll('.main-level-list-item').forEach((mainLevelListItem) => {
				if (mainLevelListItem.classList.contains('active')) {
					mainLevelListItem.classList.remove('active');
				}

			});
		}	
		if (active === true) {			
			event.target.classList.add('active');
		} else {
			if (active === false) {
				event.target.classList.remove('active');
			}
		}
		let subMenu = event.target.parentNode.querySelector('.sub-level-list');
		if (subMenu) {
			if (mode) {
				if (mode === 'top') {
					let subListCopyContainer = mainNavigationContainer.querySelector('.sub-list-copy-container');
					subListCopyContainer.innerHTML = ''; // empty it before filling it again
					if (active === true) {
						if (subListCopyContainer) {
							let subMenuCopy = subMenu.cloneNode(true);
							subListCopyContainer.appendChild(
								subMenuCopy
							);
						}	
					} else {
						subListCopyContainer.innerHTML = '';
					}
				} else {
					if (mode == 'left') {
						if (subMenu.classList.contains('hidden')) {
							//event.target.scrollIntoView(true);
							if (active === true) {
									this.onOpenSubMenu(event, subMenu, mainNavigation, mainNavigationContainer);
							}
						} else {
							subMenu.style.height = null;
							this.onCloseSubMenu(event, subMenu, mainNavigation, mainNavigationContainer);
							subMenu.classList.add('hidden');
						}
					}
				}
			}
		}
	}

	onOpenSubMenu(event, subMenu, mainNavigation, mainNavigationContainer) {
		//console.log('onOpenSubMenu', event.target, subMenu, mainNavigation);
		//keep track of the iteration (is it a button earlier or later in the document flow), so the menu can behave according to a vertical delta
		let change;
		let counter = 0;
		console.log('previousSelection', this.previousSelection);
		console.log('subMenu', subMenu);
		console.log('is previousSelection same as subMenu? =>', (this.previousSelection === subMenu));
		Array.from(mainNavigationContainer.querySelectorAll('.simplebar-content')).forEach((content) => {
			if (this.previousSelection && this.previousSelection !== subMenu) {
				console.log('there was a previous selction', this.previousSelection, this.previousSelection.scrollHeight, subMenu.scrollHeight);
				change = this.previousSelection.scrollHeight;
			}
		});
		let subMenus = mainNavigation.querySelectorAll('.sub-level-list');
		subMenus.forEach((s) => {
			counter++;
			s.dataset.iteration = counter;
			s.classList.add('hidden');
			s.style.height = '0px';
		});
		subMenu.classList.add('hidden');
		subMenu.style.height = '0px';
		subMenu.classList.remove('hidden');
		let sHeight = subMenu.scrollHeight;
		subMenu.style.height = sHeight + 'px'; // for animation when selecting a new main menu item
		let content;
		// TODO: reduce complexity here (split into logical function units)
		Array.from(mainNavigationContainer.querySelectorAll('.simplebar-content')).forEach((content) => {
			// let bar = new SimpleBar(content);
			// console.log('bar', bar);
			
			// bar.recalculate();
			// content = mainNavigationContainer;
			let eventTargetOffsetTop = event.target.offsetTop;
			//let content = mainNavigationContainer;
			let currentScrollPosition = content.scrollTop;
			// know where the current position of the currently selected main item is:
			console.log('eventTargetOffsetTop', eventTargetOffsetTop);
			// current submenu height: add it to the next content top scroll position

			// go to position of the newly selected item at click
			if (change) {
				console.log('change');				
				if (subMenu.dataset && subMenu.dataset.iteration && this.previousSelection && this.previousSelection.dataset.iteration) {
					let subMenuIteration = parseInt(subMenu.dataset.iteration);
					let previousIteration = parseInt(this.previousSelection.dataset.iteration);
					console.log('submenu iteration', subMenuIteration);
					console.log('previous selection iteration', previousIteration);
					if (subMenuIteration >= previousIteration) {
						// only act on a next item
						
						this.animationModule.animate(
						{
							element: content,
							timing(t) {
								// return t;
								// return Math.sqrt(t);
								return t<.5 ? 2*t*t : -1+(4-2*t)*t;
								// ease-in-out-cubic
								// https://gist.github.com/gre/1650294
							},
							draw(progress) {
								// content.scrollTop -= change; 
								content.scrollTop = currentScrollPosition - (change * progress);
							},
							duration: 400,
							callback: (element) => {

							}
						});
					}
				}	
			} 
			this.previousSelection = subMenu;
			console.log('setting previous selection to', this.previousSelection, this.previousSelection.scrollHeight, subMenu.scrollHeight);
		});
	}

	onCloseSubMenu(event, element, mainNavigation, mainNavigationContainer) {
		console.log('onCloseSubMenu', element, mainNavigation);
		this.previousSelection = null;
		if (mainNavigation) {
			mainNavigation.querySelectorAll('.main-level-list-item').forEach((mainLevelListItem) => {
				if (mainLevelListItem.classList.contains('active')) {
					mainLevelListItem.classList.remove('active');
					console.log('previous selection null:', this.previousSelection);
					
				}
				// this.scrollBarUtility.update();
			});
		}	
	}
	setScrollBarUtility(scrollBarUtility) {
		this.scrollBarUtility = scrollBarUtility;
	}
}