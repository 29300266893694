export class HashScrollingModule {
	initialize() {
		this.scrollToHash();
	}
	
	scrollToHash(hash = '#top') {
		$(document).ready(function() {
			// watch for #top hash and scroll to it if it exists
			if (window.location.hash === hash) {
				// IN PROGRESS: include top navigation into the equation
				// TODO: make hash based scroll work well on tablet in Computable
				let topNav = document.getElementById('navigatie');
				//console.log('topNav:', topNav);
				
				if (topNav) {
					var topNavHeight = $(topNav).height();
				}
				var scrollToElement = $(hash);
				if (scrollToElement.length && scrollToElement.length > 0) {
					var topOffset = scrollToElement.offset().top - 40; 
					//console.log('top offset of scrollToElement:', topOffset);
					
					var top = topOffset;
					if (topNavHeight) {
						//console.log('topNavHeight defined:', topNavHeight);
						top -= topNavHeight;
					}
					//console.log('scroll position: top offset of scrollToElement minus topNav height:', top);
					
					let _body = document.documentElement || document.body
					if (top > 0) {
						$([_body]).animate({
							scrollTop: top
						}, 2, function() {
							//console.log(`scrolled to ${hash}`);
						});
					} 
				}
			}
		});
	}

}