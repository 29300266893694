import * as Cookies from 'js-cookie';
import '../utilities/cookies-enabler';
import postscribe from '../utilities/postscribe';
import { AnimationModule } from '../modules/animation.module';
import { AnalyticsModule } from '../modules/analytics.module';
import { EventDispatchModule } from './event-dispatch.module';
export class CookieModule {
	constructor() {
		this.analyticsModule = new AnalyticsModule();
		if (typeof this.configurationOverride === 'undefined') {
			this.configurationOverride = {};
		}
	}
	initialize() {
		this.accepted = false; // no choice made yet
		window.postscribe = postscribe;
		this.cookiesEnablerConfiguration = {
			scriptClass: 'ce-script',
			iframeClass: 'ce-iframe',

			acceptClass: 'ce-accept',
			dismissClass: 'ce-dismiss',
			disableClass: 'ce-disable',

			bannerClass: 'ce-banner',
			bannerHTML:
				`
			<div class="cookie-consent-row fade-element-container">
				<div class="fade-element"></div>

				<div class="cookie-disclosure-column">
					<div class="margin-bottom-20"></div>
					<div class="cookie-notice-heading">Jaarbeurs maakt gebruik van cookies</div>
					<div class="margin-bottom-20"></div>
					<p>
						Jaarbeurs maakt gebruik van functionele, analytische, social media en advertentie-cookies om je website-bezoek zo eenvoudig en persoonlijk mogelijk te maken. Op deze manier kunnen wij relevante informatie en advertenties tonen en kun je eenvoudig content via je social media kanalen delen.
					</p>
					<p>
						Wil je relevante content zien, dan vragen we je hierbij om akkoord te gaan met het plaatsen van advertentie cookies en social media cookies. Wil je dit liever niet? Dan plaatsen wij alleen noodzakelijke, statistische en functionele cookies. Wij kunnen je dan helaas minder relevante content aanbieden en niet alle content tonen.
					</p>
					<p>
						Wil je meer informatie over het <a target="_blank" href="https://www.jaarbeurs.nl/documenten/lijst_gebruikte_cookies.pdf">cookiegebruik</a> en de verwerking van jouw persoonsgegevens door Jaarbeurs kijk dan in ons <a target="_blank" href="https://www.jaarbeurs.nl/nl/privacy-statement">Privacy Statement</a>.
					</p>
					<div class="hook"></div>
					<div class="margin-bottom-20"></div>
					<div class="cookie-consent-row">
						<div class="cookie-consent-column">
							<div class="cookie-button-column">
								<a href="#" class="cookie-wall-button ce-disable">Weigeren</a>
							</div>
							<div class="cookie-button-column">
								<a href="#" class="cookie-wall-button ce-accept">Accepteren</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			`,

			eventScroll: false,
			scrollOffset: 200,

			clickOutside: false,

			cookieName: 'ckaccept',
			cookieDurationNonFunctional: (365 * 10).toString(),
			cookieDurationFunctional: '1',
			cookieValueMapping: {
				deny: '0',
				allow: '1'
			},
			wildcardDomain: true,

			iframesPlaceholder: true,
			iframesPlaceholderHTML: ` 
			<p>Om deze embed te kunnen zien moet je <a href="#" class="ce-accept">niet-functionele cookies accepteren</a></p>`,
			iframesPlaceholderClass: 'ce-iframe-placeholder',

			// Callbacks
			onEnable: () => {
				this.onEnable();
			},
			onDismiss: () => {
				this.onDismiss();
			},
			onDisable: () => {
				this.onDisable();
			},
			sendAcceptanceDataToAnalyticsPlatform: true
		}
		this.animationModule = new AnimationModule();
		this.normalizeCookieAcceptanceCookieValue();
		this.createLanguageSpecificBannerMarkup();
		this.mergeDefaultConfigurationWithConfigurationOverride();
		// console.log(this.cookiesEnablerConfiguration);
		
		window.addEventListener('load', () => {
			this.onWindowLoaded();
		})
	}

	mergeDefaultConfigurationWithConfigurationOverride() {
		this.cookiesEnablerConfiguration = Object.assign(this.cookiesEnablerConfiguration, this.configurationOverride);
	}

	onWindowLoaded() {
		this.initializeCookieEnabler();
	}


	guessLanguageFromHtmlLanguage() {
		// console.log('guessLanguageFromHtmlLanguage');
		if (document && document.documentElement && document.documentElement.hasAttribute('lang') === true) {
			let language = document.documentElement.getAttribute('lang');
			// console.log('language attribute on html', language);

			return language
		}
		return null;
	}

	guessLanguageFromUri() {
		//guess language from URI
		let path = window.location.pathname;
		// console.log('path', path);
		if (path.length > 2) {
			let firstThreeCharacters = path.substr(0, 3);
			// console.log('first four characters', firstFourCharacters);
			let language = 'nl'; // default
			switch (firstThreeCharacters) {
				case '/en':
				case '/it':
					language = 'en';
					break;
				case '/nl':
					language = 'nl';
				default:
					language = null;
					break;
			}
			return language;
		}
		return null; // no language found, fall back to original cookies enabler configuration
	}

	overrideConfigurationWithEnglishMarkup() {
		this.setConfigurationOverride({
			bannerHTML: `<link href="https://fonts.googleapis.com/css?family=Maven+Pro" rel="stylesheet">
			<div class="cookie-consent-row fade-element-container">
				<div class="fade-element"></div>
	
				<div class="cookie-disclosure-column">
					<div class="margin-bottom-20"></div>
					<div class="cookie-notice-heading">Jaarbeurs uses cookies</div>
					<div class="margin-bottom-20"></div>
					<p>
						Jaarbeurs uses functional, analytics, social media and advertisement cookies to make your visit as simple and personal as possible. In this way we can deliver you relevant information and advertisements and allow you to share content via your social media channels.
					</p>
					<p>
						Would you like to see relevant content? Then we ask you to agree with the placement of advertisement and social media cookies. Would you rather not? Then we will only place necessary, statistical and functional cookies. We will then not be able to show you the most relevant content and show all content.
					</p>
					<p>
						If you would like more information about our <a target="_blank" href="https://www.jaarbeurs.nl/documenten/lijst_gebruikte_cookies.pdf">use of cookies</a> and the processing of your personal data by Jaarbeurs please refer to our <a target="_blank" href="https://www.jaarbeurs.nl/en/privacy-statement">Privacy Statement</a>.
					</p>
					<div class="margin-bottom-20"></div>
					<div class="cookie-consent-row">
						<div class="cookie-consent-column">
							<div class="cookie-button-column">
								<a href="." class="cookie-wall-button ce-disable">Refuse</a>
							</div>
							<div class="cookie-button-column">
								<a href="." class="cookie-wall-button ce-accept">Accept</a>
							</div>
						</div>
					</div>
				</div>
			</div>`,
			iframesPlaceholderHTML: ` 
			<p>To be able to see this embed you need to <a href="#" class="ce-accept">accept non-functional cookies</a></p>`,
		});
	}

	createLanguageSpecificBannerMarkup() {
		let language = this.guessLanguageFromUri();
		if (language === null) {
			language = this.guessLanguageFromHtmlLanguage();
		}
		// console.log('language', language);
		if (language) {
			switch (language) {
				case 'en':
				case 'en-US':
					this.overrideConfigurationWithEnglishMarkup();
			}
		}
	}

	initializeCookieEnabler() {
		window.COOKIES_ENABLER.init(this.cookiesEnablerConfiguration);
		let bannerSelector = '.' + this.cookiesEnablerConfiguration.bannerClass
		let banner = document.querySelector(bannerSelector);
		if (banner) {
			banner.style.visibility = 'hidden';
			this.animationModule.autoHeightAnimate(banner, 400, (element) => {
				element.classList.add('animation-done');
				let eventDispatcher = new EventDispatchModule();
				eventDispatcher.dispatch('cookieModuleLoaded');
			});
		}
	}

	onEnable() {
		this.accepted = true;
		if (
			typeof this.cookiesEnablerConfiguration.sendAcceptanceDataToAnalyticsPlatform !== 'undefined' &&
			this.cookiesEnablerConfiguration.sendAcceptanceDataToAnalyticsPlatform === true
		) {
			this.analyticsModule.getAllGoogleAnalyticsInstances().forEach(googleAnalyticsInstance => {
				let analyticsEventData = {
					'eventCategory': 'Cookiemelding',
					'eventAction': 'Keuze',
					'eventLabel': 'Ja'
				}
				googleAnalyticsInstance.send('event', analyticsEventData);
			});
		}
	}

	onDismiss() {
	}

	onDisable() {
		this.accepted = false;
		if (
			typeof this.cookiesEnablerConfiguration.sendAcceptanceDataToAnalyticsPlatform !== 'undefined' &&
			this.cookiesEnablerConfiguration.sendAcceptanceDataToAnalyticsPlatform === true
		) {
			this.analyticsModule.getAllGoogleAnalyticsInstances().forEach(googleAnalyticsInstance => {
				let analyticsEventData = {
					'eventCategory': 'Cookiemelding',
					'eventAction': 'Keuze',
					'eventLabel': 'Nee'
				}
				googleAnalyticsInstance.send('event', analyticsEventData);
			});
		}
	}

	normalizeCookieAcceptanceCookieValue() {
		let accepted = Cookies.get(this.cookiesEnablerConfiguration.cookieName);
		if (accepted) {
			accepted = parseInt(accepted, 10); // cast to decimal number
			switch (accepted) {
				case 0:
					this.accepted = false;
					break;
				case 1:
					this.accepted = true;
					break;
				default:
					this.accepted = false;
			}
		}
	}
	areCookiesAccepted() {
		return this.accepted;
	}

	setConfigurationOverride(configurationOverride) {
		this.configurationOverride = configurationOverride;
	}
}