import 'select2';
import 'select2/dist/js/i18n/nl'; // dutch

/**
 * https://select2.org/configuration/options-api
 */
export default class Select2Module {
	initialize() {
		if (typeof jQuery !== 'undefined') {
			$ = jQuery;
		}
		this.initializeSingleSelect();
		this.initializeMultipleSelect();
	}
	initializeSingleSelect() {
		let singleSelectElements = $('[data-select-single]');
		singleSelectElements.each(function(index, element) {
			let singleSelectConfiguration = {
				multiple: false,
				width: '100%'
			};
			let _element = $(element);
			if (typeof _element.attr('data-select-placeholder') !== 'undefined') {
				singleSelectConfiguration.placeholder = _element.attr('data-select-placeholder'); 
			}
			_element.select2(singleSelectConfiguration);
		});
	}
	initializeMultipleSelect() {
		let multipleSelectElements = $('[data-select-multiple]');
		let multipleSelectConfiguration = {
			multiple: true,
			closeOnSelect: false,
			width: '100%'
		};
		multipleSelectElements.each(function(index, element) {
			let _element = $(element);
			if (typeof _element.attr('data-select-placeholder') !== 'undefined') {
				multipleSelectConfiguration.placeholder = _element.attr('data-select-placeholder'); 
			}
			_element.select2(multipleSelectConfiguration);
			_element.on('change.select2', function(_event) {
				// clear the input field when a selection is made by typing
				_element.next('.select2').find('.select2-search__field').val('');
			});
		});
	}
}