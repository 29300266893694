export class EventDispatchModule {
	constructor() {
	}
	triggerEventOnElement(eventName, element) {
		// Create the event.
		var event = document.createEvent('Event');
		event.initEvent(eventName, true, true);
		element.dispatchEvent(event);
	}
	dispatch(eventName) {
		this.triggerEventOnElement(eventName, window.document);
	}
}