import '../utilities/slick';

export class SlickSliderModule {
	initialize() {
		$(document).ready(function() {
			var blockQuoteContainerSlickConfig = {
				autoplay: false,
				speed: 1500,
				fade: true,
			}
			$('.blockquote-container').slick(blockQuoteContainerSlickConfig);
		});
	}
}