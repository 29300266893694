import { BaseApplication } from './base.application';
import { ScalableVectorGraphicsModule } from './../utilities/scalable-vector-graphics.module';

import Select2Module from '../utilities/select2-module';
//import { CustomScrollBarModule } from '../utilities/custom-scroll-bar-module';
import { HashScrollingModule } from '../modules/hash-scrolling.module';
import { SlickSliderModule } from '../modules/slick-slider.module';
import { CookieModule } from '../modules/cookie.module';

export * from '../application/foundation.js'; // please keep
export class FoundationApplication extends BaseApplication {
	constructor() {
		super();
		this.iconPath = '/icons/';
		this.scalableVectorGraphicsModule;
	}
	initialize() {
		super.initialize();
		//this.initializeCustomScrollBarModule();
		this.initializeSelect2Module();
		this.initializeFoundation();
		this.initializeHashScrollingModule();
		this.initializeSlickSlider();
		this.initializeCookieModule();
	}

	initializeCookieModule() {
		this.cookieModule = new CookieModule();
		this.cookieModule.initialize();
	}

	getCookieModule() {
		return this.cookieModule;
	}

	initializeSlickSlider() {
		let slickSlider = new SlickSliderModule();
		slickSlider.initialize();
	}
	initializeSelect2Module() {
		let select = new Select2Module();
		select.initialize();		
	}
	initializeCustomScrollBarModule() {
		let customScrollBarModule = new CustomScrollBarModule();
		customScrollBarModule.initialize();
	}

	/**
 	* (re)load foundation
	* cache foundation
	 */
	initializeFoundation() {
		// @see https://github.com/foundation/foundation-sites/issues/5486
		// set namespace to prevent weird Foundation 5 font bug
		Foundation.global.namespace = '';
		let foundation = $(document).foundation(
			{
				abide: {
					patterns: {
						psswrd: /^(.){8,}$/,
						psswrdext: /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
						telefoonnl: /^[0-9-+ ]{10,}$/,
						telefoonov: /^[0-9-+ ]{8,}$/
					}
				}
			}
		);
	}

	initializeScalableVectorGraphics() {
		this.scalableVectorGraphicsModule = new ScalableVectorGraphicsModule(this.iconPath);
		this.scalableVectorGraphicsModule.initialize();
	}

	initializeHashScrollingModule() {
		let hashScrollingModule = new HashScrollingModule();
		hashScrollingModule.initialize();
	}

	resolveIconPath() {
		this.iconPath = `/wp-content/themes/${this.themeName}/global/assets/icons/`;
		if (window.location.hostname == 'localhost') {
			this.iconPath = 'icons/';
		}
	}

}