import { ScalableVectorGraphicsModule } from './../utilities/scalable-vector-graphics.module';
//import { CustomScrollBarModule } from '../utilities/custom-scroll-bar-module';
import { CookieModule } from '../modules/cookie.module';
import { BaseModule } from '../modules/base.module';
/**
 * // 
 */
export class BaseApplication extends BaseModule {
	constructor() {
		super();
		this.iconPath = '/icons/';
		this.scalableVectorGraphicsModule;
		this.triggerEventOnElement('baseApplicationConstructed', document, this);
	}
	initialize() {
		this.resolveIconPath();
		this.initializeScalableVectorGraphics();
		//this.initializeCustomScrollBarModule();
		this.triggerEventOnElement('baseApplicationInitialized', document);
	}

	setThemeName(themeName = 'betheme-child') {
		this.themeName = themeName;
	}

	setIconPath(iconPath) {
		this.iconPath = iconPath;
	}

	initializeCookieModule() {
		this.cookieModule = new CookieModule();
		this.cookieModule.initialize();
	}

	getCookieModule() {
		return this.cookieModule;
	}
	
	initializeScalableVectorGraphics() {
		this.scalableVectorGraphicsModule = new ScalableVectorGraphicsModule(this.iconPath);
		this.scalableVectorGraphicsModule.initialize();
	}

	resolveIconPath() {
		this.iconPath = `/wp-content/themes/${this.themeName}/global/assets/icons/`;
		if (window.location.hostname == 'localhost') {
			this.iconPath = 'icons/';
		}
	}

}