// based on https://gist.github.com/peduarte/7ee475dd0fae1940f857582ecbb9dc5f
export class DebounceModule {
	debounce(_function, delay = 100) {
		let timeout;
		// spread any arguments passed to this function declaration
		// and immediately return
		return function(...args) {
			// clear any time out if present
			//console.log('clearing timeout');
			
			clearTimeout(timeout);
			// call settimeout with this current
			// lexical scope and the given delay parameter
			timeout = setTimeout(() => {
				//console.log('setTimeout', this, _function);
				
				// and apply this scope and arguments to the
				// function which is to be debounced				
				_function.apply(this, args);
			}, delay);
		}
	}
}