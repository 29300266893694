import { FoundationApplication } from './foundation.application';
import { SearchModule } from './../modules/search.module';
import {VideoContainerFluidImageScalingModule } from '../modules/video-container-fluid-image-scaling.module';
import { YouTubeApiModule } from '../modules/youtube-api.module';
import { MainNavigationModule } from '../modules/main-navigation.module';
import { CustomScrollBarModule } from '../utilities/custom-scroll-bar-module';
import { Tipfy } from './../utilities/tipfy';
import { DropDownModule } from '../modules/dropdown.module';
export class Application extends FoundationApplication {
	constructor() {
		super();
		this.cookieModule = null;
		this.iconPath = '/global/assets/computable/icons/';
		console.log('location', window.location);
		switch(window.location.hostname) { 
			case 'localhost':
			case 'acc1.design-system.computable.jbitmedia.net':
				this.iconPath = '/icons/'
			break;
			case 'dev1.account.computable.nl':
			case 'acc1.account.computable.nl':
			// case 'account.computable.nl':
			// 	this.iconPath = '/global/assets/icons'
			// break;
		}		
	}
	initialize() {
		super.initialize();
		this.initializeCustomScrollBarModule();
		this.initializeVideoContainerFluidImageScalingModule();
		this.initializeYouTubeApiModule();
		this.initializeSearchModule();
		this.initializeMainNavigationModule();
		this.initializeToolTips();
		this.initializeDropDownModule();
	}

	resolveIconPath() {
		this.iconPath = '/global/assets/computable/icons/';
		if (window.location.hostname == 'localhost') {
			this.iconPath = 'icons/';
		}
	}

	initializeDropDownModule() {
		let dropDownModule = new DropDownModule();
		dropDownModule.initialize();
	}

	initializeCustomScrollBarModule() {
		let customScrollBarModule = new CustomScrollBarModule();
		customScrollBarModule.initialize();
	}

	initializeMainNavigationModule() {
		let mainNavigationModule = new MainNavigationModule();
		mainNavigationModule.initialize();
		mainNavigationModule.scrollBarUtility = this.scrollBarUtility;
	}

	initializeSearchModule() {
		let searchModule = new SearchModule();
		searchModule.setScalableVectorGraphicsModule(this.scalableVectorGraphicsModule);
		searchModule.initialize();
	}

	initializeVideoContainerFluidImageScalingModule() {
		let videoContainerFluidImageScalingModule = new VideoContainerFluidImageScalingModule();
		videoContainerFluidImageScalingModule.initialize();
	}
	initializeYouTubeApiModule() {
		let configuration = {cookiesAccepted: false};
		if (this.cookieModule && this.cookieModule.areCookiesAccepted() === true) {
			configuration.cookiesAccepted = this.cookieModule.areCookiesAccepted();
		}
		let youtubeApiModule = new YouTubeApiModule(configuration);
		youtubeApiModule.initialize('youtube-player');
	}
	initializeToolTips() {
		let tip = new Tipfy('[data-tipfy]');
	}
}