export class AnimationModule {
	autoHeightAnimate(element, time, callback) {
		//console.log('animate', element, time);
		element.style.visibility = 'visible';
		let initialHeight;
		if (element.dataset && element.dataset.initialHeight) {
			initialHeight = parseInt(element.dataset.initialHeight);
			element.style.height = initialHeight + 'px';
		} else {
			initialHeight = 0;
			element.style.height = initialHeight + 'px';
		}
		let defaultHeight = element.scrollHeight + element.style.marginTop + element.style.marginBottom;
		let defaultWidth = element.scrollWidth + element.style.marginLeft + element.style.marginRight;
		let clone = element.cloneNode(true);
		clone.style.overflow = 'hidden';
		clone.style.height = 'auto';
		clone.style.position = 'absolute';
		let animatedElementId = 'animation-' + Date.now(); 
		clone.setAttribute('id', animatedElementId);
		clone.style.width = `${defaultWidth}px`; // set width explicitely based on original element, for good measure
		// console.log('clone', clone);
		element.parentNode.appendChild(clone); //insert after the current element, to retain styles!
		let dimensionData = {
			height: clone.scrollHeight + element.style.marginTop + element.style.marginBottom,
			width: clone.scrollWidth + element.style.marginLeft + element.style.marginRight ,
		};
		// console.log('dimension data of clone', dimensionData);
		let animatedElement = document.getElementById(animatedElementId);
		if (animatedElement) {
			// console.log('animatedelement found', animatedElement);
			// go to parent node and remove the child element using the animated element id
			animatedElement.parentNode.removeChild(animatedElement);
		}
		//console.log('defaultHeight', defaultHeight);

		//element.style.height = 'auto';
		let autoHeight = dimensionData.height;

		//console.log('autoHeight', autoHeight);

		element.style.height = defaultHeight;
		//console.log('reset height to default', element.scrollHeight);
		// wait a tick
		setTimeout(() => {
			this.animate(
				{
					element: element,
					timing(timeFraction) {
						return timeFraction;
					},
					draw(progress) {
						element.style.visibility = 'visible';
						element.style.height = parseInt(initialHeight) + (autoHeight - initialHeight) * progress + 'px';
						//console.log('progress at', progress, ', height at', element.style.height);
					},
					duration: time,
					callback: (element) => {
						element.style.height = 'auto'; // finished auto height animate, set to auto again for window mutations
						if (callback) {
							callback(element);
						}
					}
				});
		},1);
	}

	/** adapted from javascript.info
	* see https://javascript.info/js-animation#structured-animation
	* 
	*/
	animate({element, timing, draw, duration, callback}) {

		let start = performance.now();

		requestAnimationFrame(function animate(time) {
			// timeFraction goes from 0 to 1
			let timeFraction = (time - start) / duration;
			if (timeFraction > 1) timeFraction = 1;

			// calculate the current animation state
			let progress = timing(timeFraction)

			draw(progress); // draw it
			// TODO: backward compatible addition of the animatable element itself

			if (timeFraction < 1) {
			requestAnimationFrame(animate);
			} else {
				callback(element);
			}

		});
		}

}